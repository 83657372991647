export const BASE_URL = process.env.VUE_APP_BASE_URL
export const WS_BASE_URL = process.env.VUE_APP_WS_BASE_URL

export const COMPANY_SERVICE_PORT =
  process.env.VUE_APP_COMPANY_SERVICE_PORT != null
    ? process.env.VUE_APP_COMPANY_SERVICE_PORT
    : ""

export const NOTIFICATIONS_SERVICE_PORT =
  process.env.VUE_APP_NOTIFICATIONS_SERVICE_PORT

export const REQUESTS_SERVICE_PORT =
  process.env.VUE_APP_REQUESTS_SERVICE_PORT != null
    ? process.env.VUE_APP_REQUESTS_SERVICE_PORT
    : ""

export const FEEDBACK_SERVICE_PORT =
  process.env.VUE_APP_FEEDBACK_SERVICE_PORT != null
    ? process.env.VUE_APP_FEEDBACK_SERVICE_PORT
    : ""

export const MESSAGES_SERVICE_PORT =
  process.env.VUE_APP_MESSAGES_SERVICE_PORT != null
    ? process.env.VUE_APP_MESSAGES_SERVICE_PORT
    : ""

export const LEGAL_SERVICE_PORT =
  process.env.VUE_APP_LEGAL_SERVICE_PORT != null
    ? process.env.VUE_APP_LEGAL_SERVICE_PORT
    : ""

export const CQC_SERVICE_PORT =
  process.env.VUE_APP_CQC_SERVICE_PORT != null
    ? process.env.VUE_APP_CQC_SERVICE_PORT
    : ""

export const WS_SERVICE_PORT =
  process.env.VUE_APP_WS_SERVICE_PORT != null
    ? process.env.VUE_APP_WS_SERVICE_PORT
    : ""

export const MAPS_KEY =
  process.env.VUE_APP_MAPS_KEY != null ? process.env.VUE_APP_MAPS_KEY : ""

export const SITE = process.env.VUE_APP_SITE
export const ENV = process.env.VUE_APP_ENV
export const SHOW_CQC = JSON.parse(process.env.VUE_APP_SHOW_CQC)
export const SHOW_CHATS = JSON.parse(process.env.VUE_APP_SHOW_CHATS)
export const SHOW_SERVICES = JSON.parse(process.env.VUE_APP_SHOW_SERVICES)
export const SHOW_ADDRESS_PICKER = JSON.parse(
  process.env.VUE_APP_SHOW_ADDRESS_PICKER
)
