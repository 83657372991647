import Vue from "vue"
import VueGtag from "vue-gtag"
import vuetify from "@/plugins/vuetify"

import { ENV, MAPS_KEY } from "@/config"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import GmapVue from "gmap-vue"

if (ENV !== "LOCAL") {
  Sentry.init({
    Vue,
    environment: ENV,
    dsn:
      "https://fd451a16533e45e8b34081ceed0347e2@o396831.ingest.sentry.io/5250714",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "company.carecompare.net",
          "company-staging.carecompare.net"
        ]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

Vue.config.productionTip = false

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta")

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "")

      return tag
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

Vue.use(GmapVue, {
  load: {
    key: MAPS_KEY,
    libraries: "places,drawing,visualization"
  },
  installComponents: true
})

Vue.use(
  VueGtag,
  {
    config: { id: "G-ENHG9HXL08" },
    enabled: false
  },
  router
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
