import { MessageService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const SetChatLastSeen = async chat_id => {
  try {
    await MessageService().patch(`/chats/${chat_id}/last_seen`)
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
