import { RequestService } from "@/api/instance"
import { DateTime } from "luxon"
import store from "@/store"
import { DeAuthSession } from "@/api/de-auth"

export const GetArchived = async () => {
  try {
    let {
      data: { enquiries }
    } = await RequestService().get("/enquiries/archived")
    if (enquiries != null && enquiries.length > 0) {
      for (let request of enquiries) {
        request.created_at = DateTime.fromISO(
          request.created_at
        ).toLocaleString()
      }
      store.commit("set_archived", enquiries)
    } else {
      store.commit("set_archived", [])
    }
  } catch (e) {
    DeAuthSession(e)
    store.commit("add_error", e)
  }
}

export const ArchiveRequest = async (id, reason) => {
  try {
    await RequestService().put(`/enquiry/${id}/archive`, { reason })
  } catch (e) {
    DeAuthSession(e)

    throw e
  }
}

export const UnarchiveRequest = async id => {
  try {
    await RequestService().put(`/unarchive/${id}`)
  } catch (e) {
    DeAuthSession(e)

    throw e
  }
}
