<template>
  <v-app id="app" style="position: relative">
    <v-snackbar
      :timeout="timeout"
      :top="true"
      :right="true"
      :color="color"
      v-model="snackbar"
      class="snackbar"
    >
      {{ text }}
      <v-btn icon color="white" @click.native="snackbar = false"
        ><v-icon>close</v-icon></v-btn
      >
    </v-snackbar>

    <router-view />
    <v-bottom-sheet
      class="cookie-notice"
      v-model="showCookieNotice"
      :inset="true"
      :persistent="true"
      :hide-overlay="true"
    >
      <v-card-text class="cookie">
        <p>
          We use cookies to provide you with the best experience when you browse
          our website and also allow us to improve our site.
        </p>
        <v-btn rounded large color="primary" @click="acceptCookieNotice"
          >Accept</v-btn
        >
        <v-btn rounded large @click="declineCookieNotice" id="decline-btn"
          >Decline</v-btn
        >
      </v-card-text>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import EventBus from "@/event-bus"

export default {
  data() {
    return {
      showCookieNotice: false,
      color: "color",
      snackbar: false,
      timeout: 1300,
      text: "placeholder",
      showFeedback: false,
      feedbackData: {
        email: "",
        feedback: ""
      }
    }
  },
  methods: {
    acceptCookieNotice() {
      this.showCookieNotice = false
      let host = location.host

      localStorage.setItem("cookie_notice", true)

      if (host === "company.carecompare.net") {
        this.$gtag.optIn()
        let user_id = this.$store.getters.self
        if (user_id != null) {
          this.$gtag.config({
            user_id: user_id
          })
        }
      }
    },
    declineCookieNotice() {
      this.showCookieNotice = false
      localStorage.setItem("cookie_notice", "false")
    }
  },
  computed: {
    not_mobile() {
      return !this.$vuetify.breakpoint.xs
    },
    logged_in() {
      return (
        this.$store.getters.self !== null || this.$store.getters.token !== null
      )
    }
  },

  watch: {
    logged_in(val) {
      if (val) {
        this.acceptCookieNotice()
      }
    }
  },
  mounted() {
    let cookie_notice = localStorage.getItem("cookie_notice")

    this.showCookieNotice = cookie_notice == null ? true : false

    if (cookie_notice === "true") {
      this.acceptCookieNotice()
    }

    EventBus.$on("toast", message => {
      this.text = message.text
      this.color = message.type
      this.snackbar = true
    })
  }
}
</script>

<style scoped>
.snackbar {
  padding-top: 64px !important;
}

::v-deep .v-snack__wrapper {
  min-width: fit-content;
}

::v-deep .v-snack__content {
  padding-right: 0;
}

::v-deep .v-bottom-sheet {
  width: fit-content !important;
}

.cookie {
  padding: 20px;
  font-size: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  width: fit-content;
}
.cookie p {
  margin: 0;
}

.cookie button {
  margin-left: 15px;
}
.cookie-notice {
  background-color: white;
}
.slide-enter-active {
  animation: slide-in 0.25s ease-out forwards;
}
.slide-leave-active {
  transition: opacity 0.25s ease;
  opacity: 0;
  animation: slide-out 0.25s ease-out forwards;
}
.slide-leave {
  opacity: 1;
  transform: translateX(0);
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 960px) {
  .cookie {
    display: inherit;
    align-items: left;
  }
  .cookie button {
    margin-left: 5px;
  }
  .cookie p {
    margin: 10px;
  }
}
</style>
