import router from "@/router"
import store from "@/store"

import * as Sentry from "@sentry/vue"

export const DeAuthSession = e => {
  if (e?.response?.status === 401) {
    store.commit("set_token", null)
    localStorage.removeItem("token")
    localStorage.removeItem("formCompleted")
    localStorage.removeItem("self")
    localStorage.removeItem("last_seen")
    Sentry.configureScope(scope => scope.setUser(null))
    router.push("/auth/login")
  }
}
