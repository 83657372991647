import { RequestService } from "@/api/instance"
import { DateTime } from "luxon"
import store from "@/store"
import { DeAuthSession } from "@/api/de-auth"
import router from "@/router.js"

export const GetRequest = async id => {
  try {
    let {
      data: { enquiry }
    } = await RequestService().get(`/enquiry/${id}`)
    if (enquiry.care != null) {
      enquiry.care.start_date_time = DateTime.fromISO(
        enquiry.care.start_date_time
      ).toLocaleString()
    } else if (enquiry.physio != null) {
      enquiry.physio.start_date_time = DateTime.fromISO(
        enquiry.physio.start_date_time
      ).toLocaleString()
    }
    return enquiry
  } catch (e) {
    if (e?.response?.status === 403 && e?.response?.data?.Redirect === true) {
      router.push({ name: "requests" })
    }
    DeAuthSession(e)

    throw e
  }
}

export const GetRequests = async (distance, search_term) => {
  let url = "/enquiries"
  let filters = []
  if (distance != null && distance > 0) {
    filters.push(`distance=${distance}`)
  }
  if (search_term != null && search_term != "") {
    filters.push(`term=${search_term}`)
  }
  url = filters.length > 0 ? (url += "?" + filters.join("&")) : url
  try {
    let {
      data: { enquiries }
    } = await RequestService().get(url)
    if (enquiries != null && enquiries.length > 0) {
      for (let request of enquiries) {
        request.created_at = new Date(request.created_at)
      }
      enquiries.sort((a, b) => a.created_at - b.created_at)

      let counter = {}

      for (let r of enquiries) {
        r.username in counter
          ? (counter[r.username] += 1)
          : (counter[r.username] = 1)
        r.count = counter[r.username]
      }

      enquiries.sort((a, b) => b.created_at - a.created_at)

      store.commit("set_requests", enquiries)
    } else {
      store.commit("set_requests", [])
    }
  } catch (e) {
    DeAuthSession(e)

    store.commit("add_error", e)
  }
}
