import Vue from "vue"
import Router from "vue-router"
import { requireAuth } from "@/auth.js"
import store from "@/store"
import { SITE } from "@/config"

const title = `${SITE.toLowerCase().replace(/^\w/, c =>
  c.toUpperCase()
)}Compare`

const Auth = resolve => {
  require.ensure(
    ["./containers/Auth.vue"],
    () => {
      resolve(require("./containers/Auth.vue"))
    },
    "auth"
  )
}

const Login = resolve => {
  require.ensure(
    ["./views/auth/Login.vue"],
    () => {
      resolve(require("./views/auth/Login.vue"))
    },
    "auth"
  )
}

const Register = resolve => {
  require.ensure(
    ["./views/auth/Register.vue"],
    () => {
      resolve(require("./views/auth/Register.vue"))
    },
    "auth"
  )
}

const ForgotPassword = () => import("./views/auth/Forgot-Password.vue")
const ResetPassword = () => import("./views/auth/Reset-Password.vue")

const Home = resolve => {
  require.ensure(
    ["./containers/Home.vue"],
    () => {
      resolve(require("./containers/Home.vue"))
    },
    "home"
  )
}
const Profile = resolve => {
  require.ensure(
    ["./views/home/Profile.vue"],
    () => {
      resolve(require("./views/home/Profile.vue"))
    },
    "profile"
  )
}

const Requests = resolve => {
  require.ensure(
    ["./views/home/Requests.vue"],
    () => {
      resolve(require("./views/home/Requests.vue"))
    },
    "home"
  )
}
const Request = resolve => {
  require.ensure(
    ["./views/home/Request.vue"],
    () => {
      resolve(require("./views/home/Request.vue"))
    },
    "home"
  )
}

const Responses = resolve => {
  require.ensure(
    ["./views/home/Responses.vue"],
    () => {
      resolve(require("./views/home/Responses.vue"))
    },
    "home"
  )
}
const Response = resolve => {
  require.ensure(
    ["./views/home/Response.vue"],
    () => {
      resolve(require("./views/home/Response.vue"))
    },
    "home"
  )
}

const Archived = resolve => {
  require.ensure(
    ["./views/home/Archived.vue"],
    () => {
      resolve(require("./views/home/Archived.vue"))
    },
    "home"
  )
}

const ArchivedRequest = resolve => {
  require.ensure(
    ["./views/home/Archived-request.vue"],
    () => {
      resolve(require("./views/home/Archived-request.vue"))
    },
    "home"
  )
}

const Accepted = resolve => {
  require.ensure(
    ["./views/home/Accepted.vue"],
    () => {
      resolve(require("./views/home/Accepted.vue"))
    },
    "home"
  )
}

const ServiceManagement = resolve => {
  require.ensure(
    ["./views/home/Services.vue"],
    () => {
      resolve(require("./views/home/Services.vue"))
    },
    "home"
  )
}

const Invoicing = resolve => {
  require.ensure(
    ["./views/home/Invoicing.vue"],
    () => {
      resolve(require("./views/home/Invoicing.vue"))
    },
    "home"
  )
}

const Chat = resolve => {
  require.ensure(
    ["./views/home/Chat.vue"],
    () => {
      resolve(require("./views/home/Chat.vue"))
    },
    "home"
  )
}

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      beforeEnter(to, from, next) {
        if (store.getters.token != null) {
          next({
            replace: true,
            path: "/home/requests"
          })
        } else if (localStorage.getItem("token") != null) {
          next({
            replace: true,
            path: "/home/requests"
          })
        } else {
          next({
            replace: true,
            path: "/auth/login"
          })
        }
      }
    },
    {
      path: "/auth",
      name: "auth",
      meta: { title },
      component: Auth,
      children: [
        {
          path: "login",
          name: "login",
          component: Login
        },
        {
          path: "register",
          name: "register",
          component: Register
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          component: ForgotPassword
        },
        {
          path: "reset-password",
          name: "reset-password",
          component: ResetPassword,
          props: route => ({ token: route.query.token })
        }
      ]
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: { title },
      beforeEnter: requireAuth,
      children: [
        {
          path: "requests",
          name: "requests",
          component: Requests
        },
        {
          path: "requests/:id",
          name: "requestsById",
          component: Request
        },
        {
          path: "responses",
          name: "responses",
          component: Responses
        },
        {
          path: "response/:id",
          name: "responsesById",
          component: Response
        },
        {
          path: "archived",
          name: "archived",
          component: Archived
        },
        {
          path: "archived/:id",
          name: "archivedById",
          component: ArchivedRequest
        },
        {
          path: "accepted",
          name: "accepted",
          component: Accepted
        },
        {
          path: "profile",
          name: "profile",
          component: Profile
        },
        {
          path: "invoicing",
          name: "invoicing",
          component: Invoicing
        },
        {
          path: "services",
          name: "services",
          component: ServiceManagement
        },
        {
          path: "chat/:id",
          name: "chat",
          component: Chat,
          beforeEnter: requireAuth,
          props: true
        }
      ]
    },
    {
      path: "*",
      beforeEnter(to, from, next) {
        const token = store.getters.token
        if (token) {
          next({ replace: true, path: "/home/requests" })
        } else {
          next({ replace: true, path: "/auth/login" })
        }
      }
    }
  ]
})
