import axios from "axios"
import {
  BASE_URL,
  COMPANY_SERVICE_PORT,
  REQUESTS_SERVICE_PORT,
  CQC_SERVICE_PORT,
  MESSAGES_SERVICE_PORT,
  LEGAL_SERVICE_PORT
} from "@/config"
import store from "@/store"

export const CompanyAuthService = axios.create({
  baseURL: `${BASE_URL}${COMPANY_SERVICE_PORT}/v1`
})

export const CompanyLogoutService = () =>
  makeAuthedService("v1", COMPANY_SERVICE_PORT)

export const CompanyService = () =>
  makeAuthedService("v1", COMPANY_SERVICE_PORT)

export const RequestService = () =>
  makeAuthedService("v1", REQUESTS_SERVICE_PORT)

export const BillingService = () =>
  makeAuthedService("v1/billing", COMPANY_SERVICE_PORT)

export const MessageService = () =>
  makeAuthedService("v1", MESSAGES_SERVICE_PORT)

export const CQCService = () => makeAuthedService("v1", CQC_SERVICE_PORT)

export const LegalUnauthedService = axios.create({
  baseURL: `${BASE_URL}${LEGAL_SERVICE_PORT}/v1`
})

export const CompanyUnauthedService = axios.create({
  baseURL: `${BASE_URL}${COMPANY_SERVICE_PORT}/v1`
})

export const LegalService = () => makeAuthedService("v1", LEGAL_SERVICE_PORT)

function makeAuthedService(path, port) {
  if (store.getters.token) {
    return axios.create({
      baseURL: `${BASE_URL}${port}/${path}`,
      headers: {
        Authorization: `Bearer ${store.getters.token}`
      }
    })
  } else {
    return axios.create({
      baseURL: `${BASE_URL}${port}/${path}`
    })
  }
}
