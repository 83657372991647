import Vue from "vue"
import Vuex from "vuex"
import { GetRequests } from "@/api/requests/requests"
import { GetArchived } from "./api/requests/archived"
import { SetChatLastSeen } from "@/api/messages/set_chat_last_seen"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    requests: [],
    current_request: null,
    responses: [],
    current_response: null,
    accepted: [],
    current_accepted: null,
    archived: [],
    current_archived: null,
    errors: [],
    self: null,
    last_seen: null,
    messages: {},
    chats: [],
    legalDocuments: [],
    profile: {
      company: {},
      address: {
        lat: 0,
        lng: 0
      },
      billing_details: {
        address: {
          lat: 0,
          lng: 0
        }
      }
    }
  },
  getters: {
    requests(state) {
      return state.requests
    },
    token(state) {
      return state.token
    },
    archived(state) {
      return state.archived
    },
    self(state) {
      return state.self
    },
    last_seen(state) {
      return state.last_seen
    },
    messages(state) {
      return state.messages
    },
    profile(state) {
      return state.profile
    },
    chat_messages: state => chat_id => {
      return state.messages[chat_id]
    },
    mostRecentChatID(state, getters) {
      if (getters.sortedChats.length === 0) {
        return ""
      }
      return getters.sortedChats[0].id
    },
    message_sender: state => (chat_id, sender_id) => {
      return state.chats.find(chat => chat.id === chat_id)?.participants[
        sender_id
      ]
    },
    chat: state => chat_id => {
      if (state.chats.length != 0) {
        let chat = state.chats.find(chat => chat.id === chat_id)
        if (chat != null) {
          return chat
        }
      }
      return {}
    },
    chats(state) {
      return state.chats
    },
    sortedChats(state) {
      return state.chats.sort(
        (a, b) =>
          (b.last_message !== null && "created_at" in b.last_message
            ? Date.parse(b.last_message.created_at)
            : Date.parse(b.created_at)) -
          (a.last_message !== null && "created_at" in a.last_message
            ? Date.parse(a.last_message.created_at)
            : Date.parse(a.created_at))
      )
    },
    checkChats: state => (client_id, company_id) => {
      let chat = state.chats.find(
        chat =>
          client_id in chat.participants && company_id in chat.participants
      )
      if (chat != null) {
        return chat.id
      }
      return null
    },
    hasUnreadMessages(state) {
      let chats = state.chats
      let messages = state.messages

      let count = 0

      if (chats.length === 0 || Object.keys(messages).length !== chats.length) {
        return count
      }

      for (let chat of chats) {
        let last_seen = chat.last_seen

        for (let message of messages[chat.id]) {
          if (last_seen === null || message.created_at > last_seen) {
            count++
          }
        }
      }
      return count
    },

    chatExists: state => id => {
      let chats = state.chats
      return chats.find(c => c.id === id) !== undefined
    },
    legalDocuments(state) {
      return state.legalDocuments
    },
    hasProfileUpdates(state) {
      let legalDocuments = state.legalDocuments
      let profile = state.profile

      let count = 0
      for (let ld of legalDocuments) {
        if (ld.consented_at == null) {
          count++
        }
      }
      if (profile.company?.logo == null || profile.company?.logo.length == 0) {
        count++
      }
      if (
        profile.company?.care_type_provided == null ||
        profile.company?.care_type_provided.length == 0
      ) {
        count++
      }
      return count
    }
  },
  mutations: {
    add_error(state, err) {
      state.errors.push(err)
    },
    set_token(state, t) {
      state.token = t
    },
    set_last_seen(state, ls) {
      state.last_seen = ls
    },
    set_requests(state, r) {
      state.requests = r
    },
    set_archived(state, r) {
      state.archived = r
    },
    set_self(state, s) {
      state.self = s
    },
    set_profile(state, p) {
      state.profile = p
    },
    add_message(state, { chat_id, msg }) {
      if (state.messages[chat_id] == null) {
        state.messages = { ...state.messages, [chat_id]: [] }
      }
      state.messages[chat_id].push(msg)
    },
    set_messages(state, { chat_id, msgs }) {
      state.messages = { ...state.messages, [chat_id]: msgs }
    },
    set_chats(state, chats) {
      state.chats = chats
    },
    set_chat_last_message(state, { chat_id, msg }) {
      if (state.chats.length != 0) {
        let chat = state.chats.find(c => c.id == chat_id)
        chat.last_message = msg
        state.chats = [chat, ...state.chats.filter(c => c.id != chat_id)]
      }
    },
    set_chat_has_seen_last(state, { chat_id, val }) {
      if (state.chats.length != 0) {
        let chat = state.chats.find(c => c.id == chat_id)
        chat.has_seen_last = val
        state.chats = [chat, ...state.chats.filter(c => c.id != chat_id)]
      }
    },
    set_chat_is_open(state, { chat_id, val }) {
      if (state.chats.length != 0) {
        let chat = state.chats.find(c => c.id == chat_id)
        chat.isOpen = val
        if (val == false) {
          chat.last_seen = new Date()
        }
        state.chats = [chat, ...state.chats.filter(c => c.id != chat_id)]
      }
    },
    set_legal_documents(state, legal_documents) {
      state.legalDocuments = legal_documents
    }
  },
  actions: {
    async SET_CHAT_LAST_SEEN({ commit }, { chat_id }) {
      await SetChatLastSeen(chat_id)
      commit("set_chat_is_open", { chat_id, val: false })
    },

    async GET_REQUESTS({ commit }) {
      try {
        await GetRequests()
      } catch (e) {
        commit("add_error", e)
      }
    },
    async GET_ARCHIVED({ commit }) {
      try {
        const r = await GetArchived()
        commit("set_archived", r)
      } catch (e) {
        commit("add_error", e)
      }
    }
  }
})
